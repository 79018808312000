<template>
  <div class="embed-container">
    <iframe :src="embedUrl" allow="autoplay" webkitAllowFullScreen mozallowfullscreen allowfullscreen />
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { useSharedStore } from "@/entrypoints/stores/shared";

export default defineComponent({
  name: "Stream",
  computed: {
    ...mapState(useSharedStore, ["election"]),
    embedUrl() {
      return this.election.streamUrl;
    },
  },
})
</script>
