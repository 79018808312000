<template>
  <div class="d-flex h-100 flex-column">
    <TabHeader :header="$t('js.sidebar.headers.comments')" />
    <div v-if="posts.length < 1" class="p-3 bg-blur-dark flex-grow-1">
      <div class="text-white-50 font-italic text-center">
        {{ $t("js.sidebar.comments.no_comments") }}
      </div>
    </div>
    <SectionList v-else :items="posts" :group-by="(post) => groupBy(post)" class="bg-blur-dark flex-grow-1"
      ref="comment_list">
      <template v-slot:headerTemplate="{ groupKey }">
        <header class="h-4" style="border-bottom: 2px solid #9B9F9B">
          <span class="text-white-50">
            {{ $t("js.slide.one") }}: {{ slides.get(Number.parseInt(groupKey)).title[firstAvailableLocale] }}
          </span>
        </header>
      </template>
      <template v-slot:itemHeaderTemplate="{ groupKey }">
        <header class="mt-2 mb-3 py-2" style="border-bottom: 1px solid #9B9F9B">
          <span class="text-white-50">
            {{ $t("js.slide.one") }}: {{ slides.get(Number.parseInt(groupKey)).title[firstAvailableLocale] }}
          </span>
        </header>
      </template>
      <template v-slot:itemTemplate="{ item }">
        <Comment v-if="item.type === 'Comment'" :item="item" />
        <div v-else-if="item.type === 'Amendment'"
          class="d-flex align-items-center justify-content-center mb-3 text-white-50">
          <i class="p-3 fas fa-file-signature"></i>
          <span class="small" v-html="$t('js.sidebar.comments.amendment_by_created_for_html', {
      name: item.owner.deleted
        ? $t('js.sidebar.general.deleted', {
          resource: $t(`js.sidebar.general.resource.${item.owner.type}`)
        })
        : item.owner.name, ballot: item.target_slide.deleted
          ? $t('js.sidebar.general.deleted', {
            resource: $t('js.sidebar.general.resource.slide')
          })
          : item.target_slide.title[firstAvailableLocale]
    })"></span>
        </div>
        <div v-else-if="item.type === 'Candidacy'"
          class="d-flex align-items-center justify-content-center mb-3 text-white-50">
          <i class="p-3 fas fa-user"></i>
          <span class="small" v-html="$t('js.sidebar.comments.candidacy_by_created_for_html', {
      name: item.owner.deleted
        ? $t('js.sidebar.general.deleted', {
          resource: $t(`js.sidebar.general.resource.${item.owner.type}`)
        })
        : item.owner.name, ballot: item.target_slide.deleted
          ? $t('js.sidebar.general.deleted', {
            resource: $t('js.sidebar.general.resource.slide')
          })
          : item.target_slide.title[firstAvailableLocale]
    })"></span>
        </div>
      </template>
    </SectionList>
    <div class="p-3 bg-blur-darker">
      <form @submit.prevent="validateForm()">
        <div class="input-group">
          <input v-model="message" :style="rtlInputStyle" type="text" class="form-control"
            :placeholder="$t('js.sidebar.comments.comment_placeholder')">
          <button class="btn btn-theme" :style="rtlButtonStyle" :disabled="submitting"
            @click.prevent="validateForm()"><i class="fas fa-paper-plane"></i></button>
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from "pinia";
import { defineComponent } from "vue";
import SectionList from "@/components/shared/SectionList.vue";
import TabHeader from "./TabHeader.vue"
import Comment from "@/components/shared/posts/Comment.vue"
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { UserPost, Slide } from "@/types";

export default defineComponent({
  name: "Comments",
  components: { Comment, TabHeader, SectionList },
  data() {
    return {
      message: "",
      response: "",
      scrolled: false,
      submitting: false,
    }
  },
  computed: {
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
    ...mapState(useVotingModulesStore, ["posts"]),
    ...mapState(usePresentationStore, ["slides", "activeSlide"]),
    slides() {
      const fake_slide = {};
      fake_slide["" + this.$i18n.locale] = this.$t("js.slide_show.no_slide_active");
      return this.posts.reduce(function (headers: any, post: UserPost) {
        if (post.slide) headers.set(post.slide.id, post.slide);
        else headers.set(0, { title: fake_slide });
        return headers;
      }, new Map());
    },
    rtlButtonStyle() {
      if (document.getElementsByTagName("html")[0].dir === "rtl") return `
          margin-right: -1px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          margin-left: 0;
          border-top-left-radius: 0.375rem;
          border-bottom-left-radius: 0.375rem;
        `;
      else return "";
    },
    rtlInputStyle() {
      if (document.getElementsByTagName("html")[0].dir === "rtl") return `
          border-top-right-radius: 0.375rem;
          border-bottom-right-radius: 0.375rem;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        `;
      else return "";
    },
  },
  methods: {
    ...mapActions(useVotingModulesStore, ["submitPost", "markAllPostsRead"]),
    async validateForm() {
      if (this.submitting) return;
      if (this.message.trim().length > 0) {
        this.submitting = true;
        const post = {
          post: {
            type: "Posts::Comment",
            message: this.message.trim(),
          },
        };
        await this.submitPost(post).then((res: any) => {
          if (res?.status === 200) {
            this.message = "";
            this.$refs.comment_list.scrolled = false;
            this.$refs.comment_list.scrollChatBottom();
          }
          this.response = res?.message;
        }).finally(() => {
          this.submitting = false;
        });
      }
    },
    groupBy(post: UserPost) {
      let group = null;
      if (post.slide) group = post.slide.id;
      else group = 0;
      return group;
    },
  },
  mounted() {
    this.markAllPostsRead("Comment");
  },
});
</script>
