<template>
  <button class="w-100 btn text-center position-relative rounded-0 py-2 overflow-hidden"
    :class="{ 'active': isActive, [color]: true }" style="line-height: 1em" @click="onClick">
    <span v-if="unreadCount" class="badge rounded-pill bg-theme-danger position-absolute" style="top: 25%; left: 80%">
      {{ unreadCount }}
    </span>
    <div class="d-flex">
      <span class="circle pulse-button" v-if="pulse"></span>
      <i class="fas" style="width: 30px" :class="icon"></i>
      <span>{{ text }}</span>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MSideBarButton",
  emits: ["click"],
  props: {
    icon: {
      type: String,
      default: "",
    },
    unread: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "btn-theme",
    },
    pulse: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    unreadCount() {
      let count = null;
      count = this.unread > 0 ? this.unread : null;
      if (this.unread > 999) count = 999;
      return count;
    },
  },
  methods: {
    onClick() {
      this.$emit("click");
    },
  },
});
</script>
