<template>
  <div class="ballot mt-3" :key="ballot.id">
    <!-- Ballot/VotingRound is closed -->
    <header class="h1 text-center">{{ title }}</header>
    <div class="lead" v-html="description" />

    <div v-if="!isBallotOpen && votingRound.contestReferences.length !== 1 && !votingRoundReport?.publishedAt"
      class="h5 mt-3">
      {{ votingRoundContests.length > 1 ? $t("js.ballot.ballots_in_slide") : $t("js.ballot.ballot_in_slide") }}
    </div>
    <div v-if="isIdentifiable && !isHandRaise" class="alert alert-info">
      <details>
        <summary class="h5 mb-0">
          {{ $t("js.ballot.anonymous_notice.header") }}
        </summary>
        <p class="mb-0">{{ $t("js.ballot.anonymous_notice.body") }}</p>
      </details>
    </div>

    <template v-if="showSupplementaryHeader">
      <ContestHeader v-for="contest in votingRoundContests" :show-description="ballot.state === 'open'"
        :contest="contest" :is-eligible="voter.canVoteOn.includes(contest.reference)" :locale="$i18n.locale" />
    </template>
    <hr>

    <template v-if="(votingRound.status === 'closed' && !isBallotOpen) || ballot.state === 'new'">
      <template v-if="ballot.state === 'finished'">
        <!-- If result is published -->
        <template v-if="!!votingRoundReport?.publishedAt">
          <template v-for="res in orchestrators">
            <template v-if="res.result">
              <Result :slide="ballot"
                :contest="contests.find((con: ConferenceContest) => con.reference === res.contestReference)"
                :result="res.result"
                :published="true"
                :header="contests.find((con: ConferenceContest) => con.reference === res.contestReference).title[$i18n.locale]"
              />
            </template>
          </template>
        </template>

        <!-- If result isn't published -->
        <div v-else class="p-3 text-center">
          <p class="mb-0">{{ $t("js.ballot.not_published") }}</p>
        </div>

      </template>
    </template>

    <!-- Ballot/VotingRound is open -->
    <template v-else>
      <!-- Can vote -->
      <template v-if="hasVotingAccess">

        <!-- Has already voted -->
        <template v-if="hasVoted">
          <BallotProgress :ballot="ballot" class="my-5" />
          <hr>
          <div class="my-5 text-center">
            <h4 class="text-success my-3"><i class="fas fa-check me-3"></i>{{ $t("js.ballot.vote_registered") }}</h4>
            <h3 v-for="contest in accessibleContests" class="contest-title">{{ contest.title[$i18n.locale] }}</h3>
            <template v-if="handRaiseLiveResults">
              <AVBallot class="my-3" :contest="formatContest(votingRoundContests[0])"
                :partial-results="handRaiseLiveResults" :observer-mode="true" :selectionPile="mockSelectionPile" />
              <hr>
            </template>
            <a v-if="receipt && canDownloadReceipt" :href="receiptUrl" class="btn btn-theme my-3">
              <i class="fas fa-download"></i>
              {{ $t("js.election_client.receipt.download_receipt") }}
            </a>
            <template v-if="!election.hide_dbas">
              <div v-if="trackingCode" class="my-3">
                <ScanQr :displayCode="trackingCode" :qrLink="trackingUrl"
                  :codeText="$t('js.election_client.receipt.tracking_code')" />
              </div>
            </template>

            <template v-if="recasting">
              <h5 class="mt-5">{{ $t("js.ballot.redo_header") }}</h5>
              <p>{{ $t("js.ballot.redo_notice") }}</p>
              <button class="btn btn-secondary" @click="startRedoVote">{{ $t("js.ballot.redo_vote") }}</button>
            </template>

            <template v-if="handRaiseLiveResults">
              <hr class="my-5" />
              <HandRaiseLivePreview />
            </template>
          </div>
        </template>

        <!-- Can vote and hasn't already voted -->
        <template v-else>
          <template v-if="!isTesting">
            <template v-if="!confirming">
              <AVSplitHelper
                :image-option="election.theme.imageOption"
                :key="activeContest.reference"
                :contest="JSON.parse(JSON.stringify(formatContest(activeContest)))"
                :contestSelection="activeContestSelection"
                :weight="voterWeight"
                :show-submission-helper="false"
                :partial-results="handRaiseLiveResults"
                :includeLazyErrors="includeLazyErrors"
                @update:contestSelection="updateActiveContestSelection"
                @update:activeState="updateActiveState"
                @update:activePile="updateActivePile"
                @update:complete="updateCompleted" />
            </template>
            <template v-else>
              <BallotSummary v-for="contestSelection in ballotSelection.contestSelections"
                :key="contestSelection.reference" :contest-selection="contestSelection" />
            </template>
          </template>

          <template v-else>
            <ChallengeBallot :client="client" :verification-code="verificationCode" :on-cast-chosen="castVote"
              @go-back="() => { isTesting = false }" />
          </template>

          <!-- Continue/Back -->
          <div v-if="!isTesting && showBallotButtons" class="d-flex mt-3 gap-3 flex-column-reverse flex-md-row">
            <button v-if="contestIndex > 0 || confirming" class="btn btn-lg btn-ballot-outline col-12 col-md-auto"
              @click="goBack" v-text="$t('js.election_client.actions.back')" />
            <button v-if="!isLastContest" :class="`btn btn-lg btn-ballot col-12 col-md-auto ${isRtl ? 'me-auto' : 'ms-auto'}`"
              :disabled="!isActiveContestComplete" @click="goForward"
              v-text="$t('js.election_client.actions.continue')" />
            <button v-else-if="election.showConfirmVotes && !confirming"
              :class="`btn btn-lg btn-ballot col-12 col-md-auto ${isRtl ? 'me-auto' : 'ms-auto'}`" :disabled="!isActiveContestComplete"
              @click="confirming = true" v-text="$t('js.election_client.actions.continue')" />
            <AsyncButton v-else-if="confirming && !hideBenaloh" :class="`btn btn-lg btn-ballot col-12 col-md-auto ${isRtl ? 'me-auto' : 'ms-auto'}`"
              :disabled="!isActiveContestComplete" @click="attemptSubmitVotes">
              {{ $t("js.election_client.ballot_verification.confirm_choices") }}
              <template #waiting>
                <span>
                  <IndeterminateSpinner /> {{ $t("js.ballot.encrypting_vote") }}
                </span>
              </template>
            </AsyncButton>
            <div v-else :class="`col-12 col-md-auto ${isRtl ? 'me-auto' : 'ms-auto'}`"
              :data-bs-original-title="!isBallotComplete ? $t('js.ballot.tooltips.ballot_state_incomplete') : null">
              <AsyncButton :on-click="attemptSubmitVotes" :disabled="!isBallotComplete"
                :style="!isBallotComplete ? { pointerEvents: 'none' } : null"
                class="btn btn-lg btn-ballot col-12 col-md-auto">
                {{ $t("js.ballot.submit_vote") }}
                <template #waiting>
                  <span><i class="fas fa-spin fa-spinner"></i> {{ $t("js.ballot.submitting_vote") }}</span>
                </template>
              </AsyncButton>
            </div>
          </div>
          <div v-if="!election.hideBenaloh" class="my-3 text-end small text-muted">
            <label for="technical-check">
              <input id="technical-check" type="checkbox" v-model="benalohOptIn" :disabled="isTesting" />
              {{ $t("js.election_client.footer.section3.header") }}
            </label>
            <i class="fas fa-info-circle ms-2 p-2"
              :data-bs-original-title="$t('js.election_client.footer.section3.label')"></i>
          </div>
          <div v-if="handRaiseLiveResults" class="mb-4">
            <hr class="mb-4 mt-3">
            <HandRaiseLivePreview />
          </div>
        </template>
      </template>

      <!-- Cannot vote -->
      <template v-else>
        <BallotProgress :ballot="ballot" class="mt-3 mb-4" />
        <div class="alert alert-info text-center">
          <p class="m-0">
            <AVIcon icon="circle-info" class="me-1" />
            {{ $t("js.ballot.cant_vote") }}
          </p>
        </div>
        <div v-if="votingRoundContests.length === 1">
          <ContestHeader :contest="votingRoundContests[0]" :is-eligible="false" :locale="$i18n.locale"
            :show-description="ballot.state === 'open'" />
          <AVBallot class="mb-5" :contest="formatContest(votingRoundContests[0])" :disabled="true"
            :partial-results="handRaiseLiveResults" :selectionPile="mockSelectionPile" />
          <div v-if="handRaiseLiveResults">
            <hr class="my-5">
            <HandRaiseLivePreview />
          </div>
        </div>
        <div v-else>
          <ContestHeader v-for="contest in votingRoundContests" :contest="contest"
            :show-description="ballot.state === 'open'" :is-eligible="false" :locale="$i18n.locale" />
        </div>
      </template>
    </template>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia";
import { defineComponent } from "vue";
import Result from "../shared/Result.vue";
import Progress from "@/components/shared/Progress.vue";
import Countdown from "../shared/Countdown.vue";
import ChallengeBallot from "./ChallengeBallot.vue";
import ContestHeader from "@/components/shared/ContestHeader.vue";
import BallotProgress from "../shared/BallotProgress.vue";
import BallotSummary from "./BallotSummary.vue";
import AsyncButton from "@/components/shared/AsyncButton.vue";
import { AVClient } from "@aion-dk/js-client";
import ContestSelectionValidator from "@aion-dk/js-client/dist/lib/validators/contestSelectionValidator";
import IndeterminateSpinner from "@/components/backend/live/IndeterminateSpinner.vue";
import ScanQr from "@/components/frontend/voting/ScanQr.vue";
import HandRaiseLivePreview from "../shared/HandRaiseLivePreview.vue";
import { formatContest, orderContests, orderResults } from "@/entrypoints/shared/contest_utilities";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import { useHandRaiseStore } from "@/entrypoints/stores/hand_raise";
import type {
  PropType,
  ConferenceContest,
  ContestSelection,
  SplitVotingState,
  SelectionPile,
  ConferenceVotingRound,
  ConferenceVotingRoundReport, HandRaiseOrchestrator,
} from '@/types';

export default defineComponent({
  inject: ["stickyAlertsSubscribe", "stickyAlertsUnsubscribe"],
  components: {
    IndeterminateSpinner,
    AsyncButton,
    BallotProgress,
    BallotSummary,
    ChallengeBallot,
    Option,
    Countdown,
    Result,
    Progress,
    ScanQr,
    ContestHeader,
    HandRaiseLivePreview,
  },
  props: {
    ballot: {
      type: Object as PropType<ConferenceContest>,
      required: true,
    },
  },
  data() {
    return {
      isActiveContestComplete: false,
      includeLazyErrors: false,
      ballotSelection: null,
      receipt: null,
      trackingCode: null,
      redoVote: false,
      client: null,
      contestIndex: 0,
      isTesting: false,
      verificationCode: "",
      confirming: false,
      activeState: "ballot",
      activePile: null,
      benalohOptIn: false,
      optionUnselectable: false,
      mockSelectionPile: {
        multiplier: 1,
        optionSelections: [],
        explicitBlank: false,
      },
      isRtl: false,
    }
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    ...mapState(usePresentationStore, ["contests", "votingRounds", "votingRoundReports", "latestConfig"]),
    ...mapState(useVotingSessionStore, ["voter", "electionCodes"]),
    ...mapState(useHandRaiseStore, ["handRaiseLiveResults"]),
    orchestrators() {
      if (!!this.votingRoundReport) {
        const filteredResults = this.votingRoundReport.resultOrchestrators?.filter((res: HandRaiseOrchestrator) =>
          this.votingRound.contestReferences.includes(res.contestReference));
        return this.orderResults(filteredResults, this.votingRound.contestPositions);
      } else return [];
    },
    title() {
      return this.ballot.title[this.$i18n.locale];
    },
    description() {
      return this.ballot.description[this.$i18n.locale];
    },
    accessibleContests() {
      const accessibleContests = this.contests.filter((contest: ConferenceContest) =>
        this.voter.canVoteOn.includes(contest.reference)
        && this.votingRound.contestReferences.includes(contest.reference)
      );
      return this.orderContests(accessibleContests, this.votingRound.contestPositions);
    },
    votingRoundContests() {
      const vrContests = this.contests.filter((contest: ConferenceContest) =>
        this.votingRound.contestReferences.includes(contest.reference));
      return this.orderContests(vrContests, this.votingRound.contestPositions);
    },
    votingRoundReport() {
      return this.votingRoundReports?.find((rep: ConferenceVotingRoundReport) =>
        rep.votingRoundReference === this.votingRound.reference);
    },
    trackingUrl() {
      return `${this.election.dbasUrl}/${this.$i18n.locale}/${this.election.organisationSlug}/${this.election.slug}/track/${this.trackingCode}`;
    },
    receiptUrl() {
      if (!this.receipt) return "";
      const url = new URL(`${this.latestConfig.items.voterAuthorizerConfig.content.voterAuthorizer.url}/${this.$i18n.locale}/download_receipt`);
      const params = url.searchParams;
      params.set('receipt', this.receipt.receipt);
      params.set("trackingCode", this.receipt.trackingCode);
      params.set("authorizationSessionId", this.client.getSessionUuid());
      return url.toString();
    },
    contest() {
      return this.accessibleContests[this.contestIndex] || {};
    },
    stickies() {
      const stickies = [];
      if (this.isBallotOpen &&
        !this.confirming &&
        !this.hasVoted &&
        !this.isTesting &&
        !this.hasVoted &&
        this.activePile &&
        this.activeState === "ballot") {
        stickies.push({
          type: "contestStatus",
          contest: this.formatContest(this.contest),
          activeSelectionPile: this.activePile,
          includeLazyErrors: this.includeLazyErrors,
        });
      }
      if (this.ballot) {
        stickies.push({
          type: "ballotStatus",
          ballot: this.ballot,
          voted: this.hasVoted,
          canVote: this.hasVotingAccess,
        });
      }
      return stickies;
    },
    isBallotComplete() {
      return this.ballotSelection.contestSelections.every((contestSelection: ContestSelection) => {
        const contestContent = this.accessibleContests.find((c: ConferenceContest) =>
          c.reference === contestSelection.reference);
        if (!contestContent) return false;
        return new ContestSelectionValidator({
          contest: this.formatContest(contestContent),
          voterWeight: contestContent.disregardWeights ? 1 : this.voter.weight,
        }).isComplete(contestSelection) && this.isActiveContestComplete;
      });
    },
    voterWeight() {
      return this.contest.disregardWeights ? 1 : this.voter.weight;
    },
    activeContestSelection() {
      return this.ballotSelection.contestSelections[this.contestIndex];
    },
    activeContest() {
      return this.accessibleContests[this.contestIndex];
    },
    votingRound() {
      return this.votingRounds.find((vr: ConferenceVotingRound) => vr.reference === this.ballot.votingRoundReference);
    },
    isIdentifiable() {
      return this.votingRound.identifiable;
    },
    isHandRaise() {
      return this.ballot.handRaise;
    },
    hasVoted() {
      if (this.redoVote) return false;
      return this.voter.votedOn?.includes(this.votingRound.reference);
    },
    isLastContest() {
      return (this.contestIndex + 1) === this.accessibleContests.length;
    },
    isBallotOpen() {
      const openStates = ["open", "countdown"];
      return openStates.includes(this.ballot.state);
    },
    hasVotingAccess() {
      return this.accessibleContests.length > 0;
    },
    hideBenaloh() {
      return this.election.hideBenaloh || !this.benalohOptIn;
    },
    recasting() {
      return this.votingRound.recasting;
    },
    resultPublished() {
      return this.ballot.state === "finished" && !!this.votingRoundReport?.publishedAt;
    },
    showSupplementaryHeader() {
      const enabledBallotStates = ["new", "closed", "calculating_result"];
      return (enabledBallotStates.includes(this.ballot.state)
        || (this.ballot.state === "finished" && !this.votingRoundReport?.publishedAt));
    },
    canDownloadReceipt() {
      return this.election.downloadVotingReceipt;
    },
    userCanSplit() {
      return this.formatContest(this.activeContest).markingType.maxPiles !== 1 && this.voterWeight > 1;
    },
    showBallotButtons() {
      return !this.userCanSplit || this.activeState === "overview";
    },
  },
  methods: {
    ...mapActions(useVotingSessionStore, ["fetchVotedOn", "reportVoted", "setVoted"]),
    formatContest,
    orderContests,
    orderResults,
    goBack() {
      this.includeLazyErrors = false;
      if (this.confirming) this.confirming = false;
      else this.contestIndex--;
      document.getElementById("MainContent").scrollTo({ top: 0, left: 0, behavior: "smooth" })
    },
    goForward() {
      this.includeLazyErrors = false;
      this.contestIndex++;
      document.getElementById("MainContent").scrollTo({ top: 0, left: 0, behavior: "smooth" })
    },
    async attemptSubmitVotes() {
      this.client = new AVClient(this.election.boardUrl, this.election.dbbPublicKey);
      await this.client.initialize();
      this.client.generateProofOfElectionCodes(this.electionCodes);
      await this.client.createVoterRegistration(this.votingRound.reference);
      this.verificationCode = await this.client.constructBallot(this.ballotSelection);
      if (this.hideBenaloh) {
        await this.castVote();
      } else {
        this.isTesting = true;
      }
    },
    async castVote() {
      this.receipt = await this.client.castBallot(null, this.$i18n.locale);
      this.trackingCode = this.receipt.trackingCode;
      this.isTesting = false;
      this.redoVote = false;
      this.confirming = false;
      this.reportVoted(this.ballot.id); // Just a ping, shouldn't await response

      // Global update without calling the DBB
      this.setVoted(this.votingRound.reference);
    },
    startRedoVote() {
      this.contestIndex = 0;
      this.redoVote = true;
      const selectionPiles = this.ballotSelection.contestSelections[this.contestIndex].piles
      const UnselectableOptions = this.contest.options.map((option) => {
        if (!option.selectable) {
          return option.reference
        }
      })
      if(selectionPiles.length > 0 ) {
        selectionPiles.filter((pile: any) => pile.optionSelections.filter((option: any) => {
          if (UnselectableOptions.includes(option.reference)) {
            return pile.optionSelections = []
          }
        }))
      }
    },
    updateActiveContestSelection(contestSelection: ContestSelection) {
      this.ballotSelection.contestSelections.splice(
        this.contestIndex,
        1,
        contestSelection,
      );
    },
    updateActiveState(newState: SplitVotingState) {
      this.activeState = newState;
    },
    updateActivePile(newPile: SelectionPile) {
      this.activePile = newPile;
    },
    updateCompleted(completed: boolean) {
      if (completed) this.includeLazyErrors = true;
      this.isActiveContestComplete = completed;
    },
  },

  watch: {
    '$i18n.locale': function() {
      this.isRtl = document.getElementsByTagName("html")[0].dir === "rtl"
    },
  },
  created() {
    this.ballotSelection = {
      reference: this.voter.voterGroupReference,
      contestSelections: this.accessibleContests.map((contest: ConferenceContest) => {
        return {
          reference: contest.reference,
          piles: [],
        }
      }),
    }

    this.isRtl = document.getElementsByTagName("html")[0].dir === "rtl"
  },
  async beforeMount() {
    await this.fetchVotedOn();
    this.stickyAlertsSubscribe(this);
  },
  beforeUnmount() {
    this.stickyAlertsUnsubscribe(this);
  },
});
</script>

<style>
.contest-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  --tw-text-opacity: 1;
  color: rgb(17 24 39);
  font-weight: 500;
}
</style>
