<template>
  <div class="mb-3 position-relative" v-tooltip=inactiveTooltip>
    <div class="mb-1 sidebar-card" :class="{ 'highlight-hover': canActivateRound }" @click="activateRound">
      <div class="p-3 mb-0 rounded-top h5" :class="canActivateRound ? 'bg-theme' : 'bg-blur-darker text-white'">
        <i class="fas fa-vote-yea"></i> {{ label }}
      </div>
      <div v-if="hasVotedOn" class="small px-1 position-absolute bg-success text-center text-white pill-rounded">
        <i class="fas fa-check"></i> {{ $t("js.voting_rounds.voted") }}
      </div>
      <div v-if="contestObjects.length > 0" class="p-3 text-white" style="max-height: 200px; overflow: auto;">
        <h6>{{ $t("js.voting_rounds.contests") }}</h6>
        <p v-for="contest in contestObjects" class="badge text-bg-secondary text-truncate mb-0 w-100">
          {{ contest.title[firstAvailableLocale] }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia";
import { label } from "@/entrypoints/shared/voting_rounds_helper";
import { orderContests } from "@/entrypoints/shared/contest_utilities";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import { defineComponent } from "vue";
import type { PropType, ConferenceVotingRound, ConferenceContest } from "@/types";

export default defineComponent({
  name: "VotingRound",
  props: {
    votingRound: {
      type: Object as PropType<ConferenceVotingRound>,
      required: true,
    },
    roundState: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
    ...mapState(usePresentationStore, ["activeSlide", "contests", "latestConfig"]),
    ...mapState(useVotingSessionStore, ["voter"]),
    hasVotedOn() {
      return this.voter.votedOn.includes(this.votingRound.reference);
    },
    inactiveTooltip() {
      if (!this.canParticipate) return this.$t("js.tooltips.ineligible_voting_round");
      if (this.controlledByActiveSlide) return this.$t("js.tooltips.controlled_by_active_slide");
      return "";
    },
    canParticipate() {
      return this.intersectingContests.length > 0;
    },
    canActivateRound() {
      return (this.roundActive || this.roundFinished)
        && this.canParticipate
        && !this.controlledByActiveSlide;
    },
    controlledByActiveSlide() {
      return this.activeSlide?.votingRoundReference === this.votingRound.reference;
    },
    voterGroupContests() {
      return this.latestConfig.items.ballotConfigs[this.voter.voterGroupReference].content.contestReferences || [];
    },
    intersectingContests() {
      return this.votingRound.contestReferences.filter((cr: string[]) => this.voterGroupContests.includes(cr));
    },
    roundActive() {
      return ["open", "active"].includes(this.roundState);
    },
    roundFinished() {
      return ["finished"].includes(this.roundState);
    },
    contestObjects() {
      const filteredContests = this.contests.filter((c: ConferenceContest) =>
        this.intersectingContests.includes(c.reference));
      return this.orderContests(filteredContests, this.votingRound.contestPositions);
    },
    label() {
      return label(this.votingRound, this.$i18n.locale);
    },
  },
  methods: {
    ...mapActions(usePresentationStore, ["setVotingRound"]),
    orderContests,
    activateRound() {
      if (!this.canActivateRound) return;
      this.setVotingRound(this.votingRound);
    },
  },
});
</script>

<style scoped lang="scss">
.highlight-hover:hover {
  cursor: pointer;
  box-shadow: 0 0 0 2px white;
}

html[dir=ltr] {
  .pill-rounded {
    border-bottom-left-radius: 10px;
    border-top-right-radius: 3px;
    top: 0;
    right: 0;
  }
}

html[dir=rtl] {
  .pill-rounded {
    border-bottom-right-radius: 10px;
    border-top-left-radius: 3px;
    top: 0;
    left: 0;
  }
}
</style>
