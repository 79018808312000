<template>
  <div class="d-flex flex-column align-items-center bg-blur-darker text-white px-1 py-3"
    v-tooltip="{ content: $t('js.sidebar.general.signed_in_as', { name: voter.name }), placement: 'left' }">
    <i class="fas fa-id-card"></i>
    <small v-text="voter.name" class="text-center" style="word-break: break-word; hyphens: auto"></small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { PropType, VotingVoter } from "@/types";

export default defineComponent({
  name: "VoterIdentifier",
  props: {
    voter: {
      type: Object as PropType<VotingVoter>,
      required: true,
    },
  },
});
</script>
