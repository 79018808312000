<template>
  <div class="slide w-100 p-0 m-0">
    <div class="container d-flex flex-column p-0 px-md-5 pt-sm-3 pt-md-5 pb-5">
      <div class="card border-0 rounded-0 shadow">
        <div v-if="showHeader"
          class="card-header d-flex flex-column flex-column-reverse flex-md-row align-items-stretch bg-theme rounded-0 border-bottom align-items-md-center p-0">
          <div v-if="election.theme.logo" class="bg-light p-3 align-self-stretch d-flex align-items-center">
            <img :src="election.theme.logo" style="max-height:50px;max-width:100%" />
          </div>
          <div class="d-flex flex-grow-1 align-items-center justify-content-between p-3">
            <h3 class="mb-0">{{ election.title[firstAvailableLocale] }}</h3>
          </div>
        </div>

        <div class="card-body p-3 px-md-5 py-lg-5 d-flex flex-column">
          <template v-if="slide.type === 'Slides::PresentationSlide'">
            <template v-if="slide.layout === 'header_with_text'">
              <header class="h1 text-center">{{ title }}</header>
              <div class="lead" v-html="description"></div>
            </template>
            <template v-else-if="slide.layout === 'header_with_image'">
              <header class="h1 text-center">{{ title }}</header>
              <img class="img-fluid align-self-center" :src="image" />
            </template>
            <div v-else-if="slide.layout === 'single_image'" class="text-center">
              <img class="img-fluid align-self-center" :src="image" />
            </div>
          </template>
          <template v-else-if="slide.type === 'Slides::BallotSlide'">
            <StickyAlerts>
              <template #before>
                <Ballot :ballot="slide" :key="`${votingRound.reference}-${votingRound.contestReferences.toString()}`" />
              </template>
            </StickyAlerts>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { defineComponent } from "vue";
import LocaleSwitcher from "../shared/LocaleSwitcher.vue";
import Ballot from "./Ballot.vue";
import StickyAlerts from "../shared/StickyAlerts.vue";
import VotingRoundResult from "@/components/backend/live/VotingRoundResult.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { PropType, Slide, ConferenceVotingRound } from "@/types";

export default defineComponent({
  components: {
    VotingRoundResult,
    StickyAlerts,
    LocaleSwitcher,
    Ballot,
  },
  name: "Slide",
  props: {
    active: {
      type: Boolean,
      default: false
    },
    slide: {
      type: Object as PropType<Slide>,
      default: () => {
        return { type: "none" };
      },
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState(usePresentationStore, ["votingRounds"]),
    ...mapState(useSharedStore, ["election", "firstAvailableLocale"]),
    title() {
      return this.slide.title[this.firstAvailableLocale];
    },
    description() {
      return this.slide.description[this.firstAvailableLocale];
    },
    image() {
      return this.slide.image;
    },
    votingRound() {
      return this.votingRounds.find((voting_round: ConferenceVotingRound) =>
        voting_round.reference === this.slide.votingRoundReference);
    },
  },
});
</script>
