<template>
  <div class="d-flex h-100 flex-column">
    <TabHeader :header="$t('js.sidebar.headers.voting_rounds')" />

    <div class="flex-grow-1 justify-content-between p-3 overflow-auto bg-blur-dark pretty-scroll-theme"
      style="height: 1px;" id="voting_rounds">
      <details open>
        <summary class="text-white-50 mb-3">
          <span class="badge text-bg-info" :class="{
            'float-end': isLtr,
            'float-start': !isLtr,
          }">
            {{ openRounds.length }}
          </span>
          <span class="h-5">
            {{ $t("js.voting_rounds.active_rounds") }}
          </span>
        </summary>
        <div v-if='openRounds.length > 0' class="mb-5">
          <VotingRound v-for="votingRound in openRounds" :votingRound="votingRound"
            :key="`voting_round_${votingRound.reference}`" :roundState="calculateState(votingRound)"
            :votedOn="votedOn(votingRound.reference) || null" />
        </div>
        <div v-else class="text-center p-3 mb-3 text-white-50">
          {{ $t("js.voting_rounds.no_active_rounds") }}
        </div>
      </details>
      <details>
        <summary class="text-white-50 mb-3">
          <span class="badge text-bg-success" :class="{
            'float-end': isLtr,
            'float-start': !isLtr,
          }">
            {{ finishedRounds.length }}
          </span>
          <span class="h-5">
            {{ $t("js.voting_rounds.finished_rounds") }}
          </span>
        </summary>
        <template v-if="finishedRounds.length > 0">
          <VotingRound v-for="votingRound in finishedRounds" :votingRound="votingRound"
            :key="`voting_round_${votingRound.reference}`"
            :roundState="calculateState(votingRound, fetchVotingRoundReport(votingRound))"
            :votedOn="votedOn(votingRound.reference) || null" />
        </template>
        <div v-else class="text-center p-3 text-white-50 mb-3">
          {{ $t("js.voting_rounds.no_finished_rounds") }}
        </div>
      </details>
    </div>
  </div>
</template>

<script lang="ts">
import { mapActions, mapState } from "pinia";
import TabHeader from "../frontend/voting/sidebar/TabHeader.vue";
import VotingRound from "./VotingRound.vue";
import { defineComponent } from "vue";
import { calculateState } from "@/entrypoints/shared/voting_rounds_helper";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import type { ConferenceVotingRound, ConferenceVotingRoundReport } from "@/types";

export default defineComponent({
  name: 'VotingRounds',
  components: { VotingRound, TabHeader },
  computed: {
    ...mapState(usePresentationStore, ["votingRounds", "votingRoundReports", "votingRound"]),
    ...mapState(useVotingSessionStore, ["voter"]),
    closedRounds() {
      return this.visibleVotingRounds.filter((vr: ConferenceVotingRound) =>
        ["closed", "past", "future"].includes(calculateState(vr, this.fetchVotingRoundReport(vr))));
    },
    openRounds() {
      return this.visibleVotingRounds.filter((vr: ConferenceVotingRound) =>
        ["open", "active"].includes(calculateState(vr)));
    },
    finishedRounds() {
      return this.visibleVotingRounds.filter((vr: ConferenceVotingRound) =>
        ["finished"].includes(calculateState(vr, this.fetchVotingRoundReport(vr))));
    },
    isDemoVoter() {
      return this.voter.demo;
    },
    visibleVotingRounds() {
      return this.votingRounds.filter((vr: ConferenceVotingRound) =>
        vr.demo === this.isDemoVoter);
    },
    isLtr() {
      return document.getElementsByTagName("html")[0].dir === "ltr";
    },
  },
  methods: {
    ...mapActions(useVotingSessionStore, ["fetchVotedOn"]),
    calculateState,
    fetchVotingRoundReport(vr: ConferenceVotingRound) {
      return this.votingRoundReports?.find((vrr: ConferenceVotingRoundReport) =>
        vrr.votingRoundReference === vr.reference);
    },
    votedOn(votingRoundReference: string) {
      return this.voter.votedOn.includes(votingRoundReference);
    },
  },
  watch: {
    votingRound: {
      handler(newVotingRound) {
        if (!newVotingRound) this.fetchVotedOn();
      }, deep: true,
    },
  },
  async mounted() {
    await this.fetchVotedOn();
  },
});
</script>
