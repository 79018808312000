<template>
  <MobileLayout :election="election" v-if="election && layout === 'mobile'" />
  <DesktopLayout :election="election" v-else-if="election && layout === 'desktop'" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState, mapActions } from "pinia";
import { electionChannelSubscription } from "@/channels/ElectionChannel";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import { useServerConnectionStore } from "@/entrypoints/stores/server_connection";
import MobileLayout from "./layouts/MobileLayout.vue";
import breakpoint from "@/entrypoints/shared/breakpoint";
import DesktopLayout from "./layouts/DesktopLayout.vue";
import visibility from "@/lib/visibility";

export default defineComponent({
  components: {
    DesktopLayout,
    MobileLayout
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    visible() {
      return visibility.visible;
    },
    layout() {
      return breakpoint.greaterEquals("sm") ? "desktop" : "mobile";
    },
  },
  watch: {
    visible: function (newState, _oldState) {
      if (newState) {
        this.updateStatus();
        this.updatePosts();
      }
    },
  },
  methods: {
    ...mapActions(usePresentationStore, ["updateStatus", "fetchLatestConfig", "subscribeToElectionChannel"]),
    ...mapActions(useVotingSessionStore, ["subscribeToVoterChannel", "prolongSession"]),
    ...mapActions(useVotingModulesStore, ["updatePosts"]),
    ...mapActions(useServerConnectionStore, ["checkWebsocketConnection"]),
  },
  created() {
    this.updateStatus();
    this.updatePosts();
    this.fetchLatestConfig();
    this.subscribeToElectionChannel();
    this.subscribeToVoterChannel();
  },
  unmounted() {
    electionChannelSubscription.value.unsubscribe();
  },
  mounted() {
    this.connectiontimer = setInterval(this.checkWebsocketConnection, 1000);
    this.sessionTimer = setInterval(this.prolongSession, 60_000);
  },
  beforeUnmount() {
    clearInterval(this.connectiontimer);
    clearInterval(this.sessionTimer);
  }
});
</script>
