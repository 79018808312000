import "@/lib/_jquery";
import "./shared/log-override";
import Rails from "@rails/ujs";
import "bootstrap";
import "@fortawesome/fontawesome-free";

import ElectionApp from "../components/frontend/voting/App.vue";
import SignUpApp from "../components/frontend/sign_up/App.vue";
import { useSharedStore } from "./stores/shared";
import { useSignUpSessionStore } from "./stores/sign_up_session";
import { useVotingSessionStore } from "./stores/voting_session";
import { useFrontendThemeStore } from "./stores/frontend_theme";
import { useServerConnectionStore } from "./stores/server_connection";
import i18n from "./shared/i18n";
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { PiniaDebounce } from "@pinia/plugin-debounce";
import { debounce } from "ts-debounce";
import { handleServerConnection } from "@/entrypoints/shared/global_event_handlers";

import * as UILibrary from "@assemblyvoting/ui-library"; // Imports components
import "@assemblyvoting/ui-library/styles"; // Imports styles

if (!window._rails_loaded) Rails.start();

document.addEventListener("DOMContentLoaded", () => {
  const electionApp = document.getElementById("election-app");

  if (electionApp) {
    const election = JSON.parse(electionApp.getAttribute("data-election"));
    const electionUrl = electionApp.getAttribute("data-url");
    const electionLocales = JSON.parse(electionApp.getAttribute("data-locales"));
    const partnerBranding = JSON.parse(electionApp.getAttribute("data-partnerBranding"));
    const organisationBranding = JSON.parse(electionApp.getAttribute("data-organisationBranding"));

    const app = createApp(ElectionApp);
    const pinia = createPinia();
    pinia.use(piniaPluginPersistedstate);
    pinia.use(PiniaDebounce(debounce));
    app.use(pinia);
    app.use(i18n);
    app.use(UILibrary.default, i18n);
    
    const sharedStore = useSharedStore();
    const frontendThemeStore = useFrontendThemeStore();
    const votingSessionStore = useVotingSessionStore();
    const serverConnectionStore = useServerConnectionStore();

    // unhandled rejections (we use this as a global event handler)
    window.onunhandledrejection = (event: PromiseRejectionEvent) => 
      handleServerConnection(votingSessionStore.destroySession, serverConnectionStore.setLostConnectionToServer, event);

    frontendThemeStore.setPartnerBranding(partnerBranding);
    frontendThemeStore.setOrganisationBranding(organisationBranding);
    sharedStore.setElectionUrl(electionUrl);
    sharedStore.setLocales(electionLocales);
    sharedStore.setElection(election);
    i18n.global.locale = sharedStore.currentLocale as any;
    sharedStore.setLocale(i18n.global.locale.toString());
    votingSessionStore.setVoting(true);

    app.mount("#election-app");
  };

  const signUpApp = document.getElementById("sign-up-app");

  if (signUpApp) {
    const election = JSON.parse(signUpApp.getAttribute("data-election"));
    const electionUrl = signUpApp.getAttribute("data-url");
    const electionLocales = JSON.parse(signUpApp.getAttribute("data-locales"));
    const partnerBranding = JSON.parse(signUpApp.getAttribute("data-partnerBranding"));
    const organisationBranding = JSON.parse(signUpApp.getAttribute("data-organisationBranding"));

    const app = createApp(SignUpApp);
    const pinia = createPinia();
    pinia.use(piniaPluginPersistedstate);
    app.use(pinia);
    app.use(i18n);
    app.use(UILibrary.default, i18n);

    const sharedStore = useSharedStore();
    const signUpSessionStore = useSignUpSessionStore();
    const frontendThemeStore = useFrontendThemeStore();
    const serverConnectionStore = useServerConnectionStore();

    // unhandled rejections (we use this as a global event handler)
    window.onunhandledrejection = (event: PromiseRejectionEvent) => 
      handleServerConnection(signUpSessionStore.destroySession, serverConnectionStore.setLostConnectionToServer, event);

    frontendThemeStore.setPartnerBranding(partnerBranding);
    frontendThemeStore.setOrganisationBranding(organisationBranding);
    sharedStore.setElectionUrl(electionUrl);
    sharedStore.setLocales(electionLocales);
    sharedStore.setElection(election);
    i18n.global.locale = sharedStore.currentLocale as any;
    sharedStore.setLocale(i18n.global.locale.toString());

    app.mount("#sign-up-app");
  }
});

$(function () {
  $("#compatibilityModal").modal("show");
});

$(function() {
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  // We listen to the resize event
  window.addEventListener("resize", () => {
    // We execute the same script as before
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
});
