<template>
  <div class="d-flex h-100 flex-column">
    <TabHeader :header="$t('js.sidebar.headers.candidacies')" />
    <div class="flex-grow-1 justify-content-end p-3 overflow-auto bg-blur-dark pretty-scroll-theme" style="height: 1px;"
      id="chat" @scroll="checkScroll">
      <div v-if="candidacies.length < 1" class="text-white-50 font-italic text-center">
        {{ $t("js.sidebar.candidacies.no_candidacies") }}
      </div>
      <Candidacy v-for="item in candidacies" :item="item" :key="`candidacy_${item.id}`" />
    </div>
    <div class="p-3 text-white bg-blur-darker">
      <button class="btn btn-theme" @click.prevent="showNewForm = true" v-if="!showNewForm">
        <i class="fas fa-user-plus"></i>
        {{ $t("js.sidebar.candidacies.sign_up_as_candidate") }}
      </button>
      <form @submit.prevent="validateForm()" v-if="showNewForm">
        <button type="button" class="btn btn-link float-end text-white" @click.prevent="showNewForm = false">
          <i class="fas fa-times"></i>
        </button>
        <h4>{{ $t("js.sidebar.candidacies.sign_up_as_candidate") }}</h4>
        <div v-if="responseStatus !== 200 && responseErrors" class="bg-theme-danger text-white p-3 my-2">
          <b>{{ $t("js.form.please_correct_the_following") }}</b>
          <ul class="mb-0">
            <li v-for="(errors, propertyName ) in responseErrors">
              <span v-for="error in errors">
                {{ $t(`js.forms.candidacy.errors.${error.error}`, {
                  property:
                    $t(`js.properties.candidacy.${propertyName}`)
                }) }}
              </span>
            </li>
          </ul>
        </div>
        <div class="mb-3">
          <label for="associated_ballot_candidacy">{{ $t("js.sidebar.candidacies.associated_ballot") }}</label>
          <select id="associated_ballot_candidacy" class="form-control" v-model="target_slide_id" required>
            <option v-for="slide in ballotSlides" :value="slide.id" :selected="slide.id === target_slide_id">
              {{ slide.title[firstAvailableLocale] }}
            </option>
          </select>
        </div>
        <label for="motivation_candidacy">{{ $t("js.sidebar.candidacies.motivation") }}</label>
        <div class="input-group mb-3">
          <textarea id="motivation_candidacy" v-model="message" type="text" class="form-control" rows="3"
            :placeholder="$t('js.sidebar.candidacies.motivation_placeholder')" required></textarea>
        </div>
        <button type="submit" class="btn btn-theme" @click.prevent="validateForm()" :disabled="submitting">
          <i class="fas fa-paper-plane"></i>
          {{ $t("js.sidebar.candidacies.sign_up") }}
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from "pinia";
import { defineComponent } from "vue";
import TabHeader from "./TabHeader.vue";
import Candidacy from "@/components/shared/posts/Candidacy.vue";
import { useVotingModulesStore } from "@/entrypoints/stores/voting_modules";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { UserPost, Slide } from "@/types";

export default defineComponent({
  name: "Candidacies",
  components: { Candidacy, TabHeader },
  data() {
    return {
      showNewForm: false,
      target_slide_id: null,
      message: "",
      responseMessage: "",
      responseErrors: null,
      responseStatus: null,
      scrolled: false,
      submitting: false,
    }
  },
  computed: {
    ...mapState(useSharedStore, ["firstAvailableLocale"]),
    ...mapState(useVotingModulesStore, ["posts"]),
    ...mapState(usePresentationStore, ["slides", "activeSlide"]),
    candidacies() {
      return this.posts.filter((p: UserPost) => p.type === "Candidacy");
    },
    ballotSlides() {
      return this.slides.filter((s: Slide) => s.type === "Slides::BallotSlide");
    },
  },
  watch: {
    posts: {
      handler() {
        if (!this.scrolled) {
          this.$nextTick(function () {
            const d = $("#chat");
            d.scrollTop(d.prop("scrollHeight"));
          });
        }
      }, deep: true,
    },
  },
  methods: {
    ...mapActions(useVotingModulesStore, ["submitPost"]),
    ...mapActions(usePresentationStore, ["showToast"]),
    validateForm() {
      if (this.submitting) return;
      this.submitting = true;
      this.errors = [];
      this.submitPost({
        post: {
          type: "Posts::Candidacy",
          message: this.message,
          target_slide_id: this.target_slide_id,
        }
      }).then((res: any) => {
        if (res.status === 200) {
          this.message = "";
          this.showNewForm = false;
        }
        this.responseMessage = res.data.message;
        this.responseStatus = res.status;

        this.showToast({
          header: this.$t("js.toasts.created.candidacy"),
          body: "",
          duration: 5,
          classes: {
            header: "bg-success text-white",
          },
        });
      }).catch((error: any) => {
        this.responseStatus = error.response.status;
        this.responseErrors = error.response.data.errors;
      }).finally(() => {
        this.submitting = false;
      });
    },
    checkScroll() {
      const d = $("#chat");
      this.scrolled = (d.scrollTop()) < (d.prop("scrollHeight") - d.prop("offsetHeight"));
    },
  },
  mounted() {
    if (this.activeSlide && this.activeSlide.type === "Slides::BallotSlide") {
      this.target_slide_id = this.activeSlide.id;
    }

    this.$nextTick(function () {
      const d = $("#chat");
      d.scrollTop(d.prop("scrollHeight"));
    });
  },
});
</script>
