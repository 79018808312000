<template>
  <Modal :visible="!!votingRound" maxWidth="50rem" :closable="false">
    <template #header>
      <div class="modal-title text-muted">
        {{ label(votingRound, $i18n.locale) }}
      </div>
      <button type="button" class="btn-close m-0" @click="closeModal" />
    </template>
    <template #outerBody>
      <BallotEmbedded v-if="votingRound" class="modal-body p-0" :voting-round="votingRound" />
    </template>
  </Modal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapState } from "pinia";
import { label } from "@/entrypoints/shared/voting_rounds_helper";
import Modal from "../../shared/Modal.vue";
import { AVClient } from "@aion-dk/js-client";
import BallotEmbedded from "./BallotEmbedded.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import { useVotingSessionStore } from "@/entrypoints/stores/voting_session";
import type { ConferenceVotingRound } from "@/types";


export default defineComponent({
  name: "VotingRoundModal",
  components: { BallotEmbedded, Modal },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    ...mapState(useVotingSessionStore, ["electionCodes"]),
    ...mapState(usePresentationStore, ["votingRound"]),
    roundFinished() {
      return this.votingRoundState === "finished";
    },
  },
  methods: {
    label,
    ...mapActions(usePresentationStore, ["setVotingRound"]),
    closeModal() {
      const votingRoundToExpire = this.votingRound;
      const finished = this.roundFinished;
      this.setVotingRound(null);

      if (!finished) this.cancelVoting(votingRoundToExpire);
    },
    async cancelVoting(votingRoundToExpire: ConferenceVotingRound) {
      try {
        const client = new AVClient(this.election.boardUrl, this.election.dbbPublicKey);
        await client.initialize();
        client.generateProofOfElectionCodes(this.electionCodes);
        await client.expireVoterSessions(votingRoundToExpire.reference);
      } catch (e) {
        console.error(e);
      }
    },
  },
});
</script>
