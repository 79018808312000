<template>
  <div class="d-flex flex-column gap-3 mt-3 pt-3" :aria-label="$t('accessibility.aria.label.ballot')">
    <h5 class="mb-3 summary-ballot-title">{{ title }}</h5>
    <div v-for="(selectionPile, pileIndex) in contestSelection.piles" :key="JSON.stringify(selectionPile)"
      class="d-flex flex-column">
      <AVPileSummary :contest="formatContest(contest)" :selection-pile="selectionPile" :pile-index="pileIndex"
        :total-piles="contestSelection.piles.length" active-state="summary" :key="contest.reference"
        :locale="$i18n.locale" />
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { defineComponent } from "vue";
import { formatContest } from "@/entrypoints/shared/contest_utilities";
import { usePresentationStore } from "@/entrypoints/stores/presentation";
import type { PropType, ContestSelection, ConferenceContest } from "@/types";

export default defineComponent({
  props: {
    contestSelection: {
      type: Object as PropType<ContestSelection>,
      required: true,
    },
  },
  computed: {
    ...mapState(usePresentationStore, ["contests"]),
    title() {
      return this.contest.title ? this.contest.title[this.$i18n.locale] : "";
    },
    contest() {
      return this.contests.find((contest: ConferenceContest) =>
        contest.reference === this.contestSelection.reference);
    },
  },
  methods: {
    formatContest,
    async testVote() {
      this.isTesting = true;
      try {
        await this.client.spoilBallot();
        this.pairingCode = (await this.client.waitForVerifierRegistration()) || "";
      } catch (error) {
        console.error(error);
      }
    },
    async confirmPairingCode() {
      try {
        await this.client.challengeBallot();
        this.pairingCodeConfirmed = true;
      } catch (error) {
        console.error(error);
      }
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.verificationCode);
      this.copied = true;
    },
  },
});
</script>

<style>
.summary-ballot-title {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: 2rem;
  color: rgb(17, 24, 39);
  font-weight: 500;
}
</style>
