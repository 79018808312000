<template>
  <div class="ballot mt-3">
    <div v-if="!isTesting">
      <h4>{{ $t("js.election_client.ballot_verification.ballot_ready") }}</h4>
      <p class="lead mt-3" v-html="$t('js.election_client.ballot_verification.ballot_options_html')"></p>

      <details>
        <summary>{{ $t("js.election_client.ballot_verification.what_is_testing") }}</summary>
        <p class="text-muted" v-html="$t('js.election_client.ballot_verification.testing_details_html')"></p>
      </details>
      <details>
        <summary>{{ $t("js.election_client.ballot_verification.what_is_casting") }}</summary>
        <p class="text-muted" v-html="$t('js.election_client.ballot_verification.casting_details_html')"></p>
      </details>
      <ScanQr :displayCode="verificationCode" :qrLink="verifyDirectLink"
        :codeText="$t('js.election_client.ballot_verification.your_verification_code')" />

      <div class="d-flex mt-3 gap-3 flex-column-reverse flex-sm-row">
        <button key="test-ballot-back"
          :class="`btn btn-lg btn-outline-dark ${ isRtl ? 'ms-sm-auto' : 'me-sm-auto' }`"
          @click="$emit('goBack')">
          {{ $t("js.election_client.actions.back") }}
        </button>

        <AsyncButton class="btn btn-lg btn-outline-dark" @click="testVote">
          {{ $t("js.election_client.ballot_verification.test_vote") }}
        </AsyncButton>
        <AsyncButton class="btn btn-lg btn-theme" @click="castVote" :wait-time="500">
          {{ $t("js.election_client.ballot_verification.cast_vote") }}
          <template #waiting>
            <span class="hstack gap-2">
              <IndeterminateSpinner />
              <span v-text="$t('js.election_client.ballot_verification.casting_vote')" />
            </span>
          </template>
        </AsyncButton>
      </div>
    </div>

    <div v-else>
      <h4>{{ $t("js.election_client.ballot_verification.ballot_verification") }}</h4>
      <div v-if="!pairingCode">
        <div class="d-flex justify-content-center flex-column align-items-center">
          <IndeterminateSpinner class="h1 mt-3" :line-thickness="2" />
          <p class="lead mt-3">{{ $t("js.election_client.ballot_verification.waiting_for_pairing") }}</p>
          <details v-if="takingLong" class="mb-3">
            <summary class="lead" v-if="takingLong">{{ $t("js.election_client.ballot_verification.initiate_question") }}
            </summary>
            <p class="text-muted" v-html="$t('js.election_client.ballot_verification.testing_takes_long_html')"></p>
            <ScanQr :displayCode="verificationCode" :qrLink="verifyDirectLink"
              :codeText="$t('js.election_client.ballot_verification.your_verification_code')" />
          </details>
        </div>

        <div class="row mt-3">
          <div class="col-12 col-sm-6 mb-3 mb-sm-0 order-2 order-sm-1 d-flex justify-content-start">
            <button class="btn btn-lg btn-outline-dark full-width-mobile" @click="$emit('goBack')">
              {{ $t("js.election_client.actions.back") }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="pairingCode && !pairingCodeConfirmed" class="col-sm-12 mt-3">
        <p class="lead">
          {{ $t("js.election_client.ballot_verification.verification_found_ballot") }}
        </p>
        <p class="lead">
          {{ $t("js.election_client.ballot_verification.verification_instruction_confirm") }}
        </p>

        <details class="mb-3">
          <summary>{{ $t("js.election_client.ballot_verification.what_if_pairing_code") }}</summary>
          <p class="text-muted">
            {{ $t("js.election_client.ballot_verification.what_if_pairing_code_details") }}
          </p>
        </details>
        <div class="bg-white border text-center position-relative rounded">
          <p class="p-1 fw-bold mb-0">
            {{ $t("js.election_client.ballot_verification.your_pairing_code") }}
          </p>
          <h1 class="text-dark mb-3">
            <code class="text-primary" style="letter-spacing: 5px">{{ pairingCode }}</code>
          </h1>
          <div class="btn-group w-100 border-top rounded-0 rounded-bottom overflow-hidden">
            <button class="btn btn-lg btn-light text-danger float-sm-end w-50 rounded-0" @click="$emit('goBack')">
              {{ $t("js.election_client.ballot_verification.codes_dont_match") }}
            </button>
            <button class="btn btn-lg btn-light text-success border-start float-sm-end w-50 rounded-0"
              @click="confirmPairingCode">
              {{ $t("js.election_client.ballot_verification.codes_match") }}
            </button>
          </div>
        </div>
      </div>

      <div v-if="pairingCodeConfirmed">
        <hr />
        <p class="lead">
          {{ $t("js.election_client.ballot_verification.you_have_tested") }}
        </p>
        <details>
          <summary>{{ $t("js.election_client.ballot_verification.what_if_choices") }}</summary>
          <p class="text-muted">
            {{ $t("js.election_client.ballot_verification.what_if_choices_details") }}
          </p>
        </details>
        <div class="d-flex justify-content-end">
          <button class="mt-3 btn btn-lg btn-dark  full-width-mobile" @click="$emit('goBack')">
            {{ $t("js.election_client.ballot_verification.return_to_voting") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from "pinia";
import { defineComponent } from "vue";
import IndeterminateSpinner from "@/components/backend/live/IndeterminateSpinner.vue";
import AsyncButton from "@/components/shared/AsyncButton.vue";
import ScanQr from "./ScanQr.vue";
import { useSharedStore } from "@/entrypoints/stores/shared";
import type { PropType, IAVClient } from "@/types";

export default defineComponent({
  components: { IndeterminateSpinner, AsyncButton, ScanQr },
  props: {
    client: {
      type: Object as PropType<IAVClient>,
      required: true,
    },
    verificationCode: {
      type: String,
      required: true,
    },
    onCastChosen: {
      type: Function,
      required: true,
    },
    onGoBack: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isTesting: false,
      pairingCode: null,
      pairingCodeConfirmed: false,
      copied: false,
      takingLong: false,
      isRtl: false,
    }
  },
  computed: {
    ...mapState(useSharedStore, ["election"]),
    dbasUrl() {
      return `${this.election.dbasUrl}/${this.$i18n.locale}/${this.election.organisationSlug}/${this.election.slug}`;
    },
    verifyDirectLink() {
      return `${this.dbasUrl}/verify/${this.verificationCode}/found`;
    },
  },
  methods: {
    async testVote() {
      try {
        setTimeout(() => {
          this.takingLong = true;
        }, 5000);
        await this.client.spoilBallot();
        this.isTesting = true;
        this.pairingCode = (await this.client.waitForVerifierRegistration()) || "";
      } catch (error) {
        console.error(error);
      }
    },
    async confirmPairingCode() {
      try {
        await this.client.challengeBallot();
        this.pairingCodeConfirmed = true;
      } catch (error) {
        console.error(error);
      }
    },
    async castVote() {
      await this.onCastChosen();
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.verificationCode);
      this.copied = true;
    },
  },
  watch: {
    "$i18n.locale"() {
      this.isRtl = document.getElementsByTagName("html")[0].dir === "rtl";
    },
  },
  mounted() {
    this.isRtl = document.getElementsByTagName("html")[0].dir === "rtl";
  },
});
</script>
